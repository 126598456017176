import React from "react";
import Layout from "../../../Layout";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../page-style.scss";

const data = (function () {
  let imgArray = [];
  for (let i = 1; i <= 20; i++) {
    let imgUrl = `https://chinmayavvdelhi.ac.in/assets/images/calendar/22-dec-23-1/pic${i}.jpeg`;
    imgArray.push(imgUrl);
  }

  return imgArray;
})();

export default function NationalMathematicsDay2023() {
  const settings = {
    infinite: true,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  function ImageSlider() {
    return (
      <div className="calendar-page__slider">
        <Slider {...settings}>
          {data.map((item, i) => (
            <div className="calendar-page__images__slide">
              <div className="calendar-page__images__card">
                <img alt="" src={item}></img>
              </div>
              <br />
              <center>
                <h3>{item.titleSubHead}</h3>
              </center>
            </div>
          ))}
        </Slider>
      </div>
    );
  }

  return (
    <Layout>
      {/* <SEO
        title="School Gallery | Chinmaya Vidyalaya Vasant Vihar"
        description="Explore photos and videos of the campus and student activities  at the Vidyalaya for a holistic view of student life."
      /> */}

      <div className="calendar-page">
        <div className="goback">
          <a href="/school-events/">
            <i className="fa-solid fa-arrow-left"></i>Go Back
          </a>
        </div>
        <h1 className="heading">National Mathematics Day</h1>

        <p>
          National Mathematics Day is celebrated to mark the birth anniversary
          of one of India’s most famous mathematicians, Srinivasa Ramanujan.
          <br />
          <br />
          The Primary Wing of Chinmaya Vidyalaya held a Special Assembly to
          honour him and introduce the learners to his life and works. The
          critically acclaimed movie ‘The Man Who Knew Infinity’ was a biography
          based on him.
          <br />
          <br />
          Ramanujan made substantial contributions to the analytical theory of
          numbers and worked on elliptic functions, continued fractions, and
          infinite series. 
          <br />
          <br />
          During the assembly, children performed Angle Yoga which combined the
          Yogasanas with geometrical shapes.
          <br />
          <br />
          The students also learned about the number zero and the fascinating
          facts associated with it.
        </p>
        <section className="calendar-page__videosection">
          <ImageSlider />
        </section>
      </div>
    </Layout>
  );
}
